<template>
  <div class="main">
   <Header/>
    <div class="main-box">
      <div @click="NewsContent(i.id)" class="main-box-item" v-for="i in NewsList">
        <span class="item-left"
          >{{i.title}}</span
        >
        <span class="item-right">{{i.create_time.slice(0,11)}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
export default {
  data() {
    return {
        NewsList:[],//新闻列表每一项
    };
  },
  created() {
    this.getNewsList();
  },
  methods: {
    // 跳到新闻详情页面
    NewsContent(id) {
      this.$router.push(`/reviewNewsContent?id=${id}`);
    },
    // 请求新闻标题列表
    getNewsList() {
      this.axios
        .get(`/index/review_list?p_id=${this.$route.query.pid}`)
        .then((res) => {
          console.log(res);
          console.log(res.data.list);
          this.NewsList=res.data.list;
          
        });
    },
  },
  components:{
     Header,
  }
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  min-height: 100vh;
  background: #f8f8f8;
  padding-top: 20px;
  padding-bottom: 30px;
  .main-box {
    width: 1000px;
    height: 800px;
    background: #fff;
    margin: 0 auto;
    overflow-y: scroll;
    .main-box-item {
      height: 50px;
      line-height: 50px;
      // background:pink;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px dashed #9999;
      padding-left: 10px;
      padding-right: 10px;
      .item-left {
        display: block;
        width: 80%;
        font-size: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .item-right {
        font-size: 14px;
      }
    }
    .main-box-item:hover{
      cursor:pointer;
    }
  }
}
</style>
